.search-section-container {
  padding: 100px 0 0px;
  /* background: url(../images/img01.png) no-repeat right top; */
  background-size: contain;
  background-image: url("https://karishye.co/wp-content/themes/Karishye-child/img/bell.png");
  background-position: top right;
  background-repeat: no-repeat;
}
.All__pujas-container {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 47px;
}
.search-section-heading {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 40px;
  color: #5a1740;
}
.top-search-section {
  width: 100%;
  background-color: white;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  /* height: 389px; */
  margin-top: 30px;
  max-width: 760px;
}
.search-container {
  padding: 28px;
}
.searchable {
  width: 100%;
  position: relative;
}
.search-input {
  background: #ffffff;
  border: 1px solid #a7a7a7;
  font-size: 18px;
  font-family: "Quicksand", sans-serif;
  border-radius: 0;
  padding: 15px;
  height: auto;
  font-weight: 500;
  color: #736a6f !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  appearance: none !important;
  background-color: transparent;
}

.searchable ul {
  display: none;
  list-style-type: none;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  border: 1px solid #cfcfcf;
  border-top: none;
  max-height: 240px;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0;
  position: absolute;
  z-index: 100;
  width: 100%;
}
.searchable ul li {
  padding: 10px 25px;
  background-color: #fff;
  cursor: pointer;
  color: #000;
  width: 100%;
}

.categories {
  display: flex;
  /* justify-content: space-between; */
  align-items: baseline;
  padding-bottom: 25px;
  margin-top: 13px;
  flex-wrap: wrap;
}
.active {
  display: flex;
}
.choosecategory {
  font-size: 19px;
  font-weight: 600;
  margin-top: 26px;
  color: #5a1740;
  margin-bottom: 26px;
}
.category {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 120px;

  margin: 15px;
  position: relative;
  height: 70px;
  margin-bottom: 1.5rem;
}
.category img {
  /* width: 40px; */
  height: 40px;
  margin-bottom: 10px;
}
.category-type {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #5a1740;
}
.categories > .active::after {
  content: " ";
  width: 50px;
  height: 4px;
  background-color: #5a1740;
  position: absolute;
  top: 84px;
}

.bottom-search-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.search-button {
  background: #5a1740;
  border: 1px solid #5a1740;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  width: 150px;
  height: 42px;
}
.All__pujas-container {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 77px;
}

.section-two-heading {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 96px;
  color: #5a1740;
  margin-bottom: 40px;
}
.active {
  display: flex;
}
.puja-card-col {
  margin-bottom: 50px;
  margin-right: 40px;
  max-width: 30%;
}

.each-puja-card {
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 30px;
  background: #ffffff;
  border: 1px solid #5a1740;
  box-shadow: 0px 4px 16px rgba(90, 23, 64, 0.16);
  justify-content: space-between;
  cursor: pointer;
}
.each-puja-card-image {
  width: 100%;
  height: 167px;
  margin-bottom: 20px;
  position: relative;
}
.each-puja-card-image > img {
  height: 100%;
  width: 100%;
}
.each-puja-card .each-puja-card-title {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #262525;
  margin-bottom: 11px;
  min-height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.each-puja-card-description {
  height: 166px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #262525;
  font-weight: 600;
  font-size: 18px;
  margin-top: 18px;
}

.each-puja-card {
  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 30px;
  background: #ffffff;
  border: 1px solid #5a1740;
  box-shadow: 0px 4px 16px rgba(90, 23, 64, 0.16);
  justify-content: space-between;
  cursor: pointer;
}
.card-bottom {
  display: flex;
}
.card-puja-price-div {
  display: flex;
  align-items: center;
}
.card-bottom div:nth-child(2) {
  margin-left: auto;
}
.book-now-btn {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 26px;
  padding-right: 26px;
  border: 1px solid #5a1740;
  background-color: #ffffff;
  color: #5a1740;
  margin-top: 1rem;
  font-weight: 600;
  font-family: "Quicksand";
  float: right;
}

.pujs__contsiner {
  flex-wrap: wrap;
  display: flex;
}

.All__pujas-container {
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 77px;
}

@media screen and (max-width: 1440px) {
  .All__pujas-container {
    padding-left: 7%;
    padding-right: 7%;
  }
}
@media screen and (max-width: 900px) {
  .search-container {
    padding: 15px;
  }
  .All__pujas-container {
    padding-left: 5%;
    padding-right: 5%;
  }
  .category-type {
    font-family: "Quicksand";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    text-align: center;
    color: #5a1740;
  }
  .section-two-heading {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 63px;
    line-height: 96px;
    color: #5a1740;
    margin-bottom: 40px;
  }
  .book-now-btn {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 19px;
    border: 1px solid #5a1740;
    background-color: #ffffff;
    color: #5a1740;
    margin-top: 1rem;
    font-weight: 600;
    font-family: "Quicksand";
    float: right;
    font-size: 14px;
  }
  .puja-card-col {
    margin-bottom: 50px;
    margin-right: 0px;
    max-width: 100%;
  }
  .each-puja-card {
    padding-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
  }
  .card-gold-line {
    width: 100%;
  }
  .each-puja-card-description {
    height: 163px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: #262525;
    font-weight: 600;
    font-size: 13px;
    margin-top: 18px;
  }
  .each-puja-card .each-puja-card-title {
    font-family: "Playfair Display";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #262525;
    margin-bottom: 11px;
    min-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
