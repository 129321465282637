@import url("https://fonts.googleapis.com/css2?family=Maharlika:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap");
body {

  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  #root {
    // height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;

    * {

      outline: none;
      box-sizing: border-box;
    }
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
