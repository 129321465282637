.abtpgfstcontsec {
  width: 100%;
  overflow: hidden;
  height: 100vh;
  background-image: url("https://karishye.co/wp-content/themes/Karishye-child/images/about-us-top.png");
  background-repeat: no-repeat;
  background-position: right center, left top;
  background-size: 50% auto, 100% 100%;
  padding-top: 80px;
}
.herosection-text {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.abtpgfstcontsec h1 {
  font-family: "Fraternite";
  color: #5a1740;
  font-weight: 700;
  font-style: normal;
  font-size: 80px;
  line-height: 86px;
  padding: 0 0 15px 0;
  font-size: 65px;
  line-height: 81px;
  max-width: 585px;
}
.abtpgsndcontsec h2 {
  font-family: "Fraternite";
  color: #5a1740;
  font-weight: 700;
  font-style: normal;
  font-size: 65px;
  line-height: 65px;
  margin-bottom: 4rem;
  margin-top: 4rem;
}
.abtpgfstcontsec p {
  color: #262525;
  font-weight: 600;
  font-style: normal;
  font-size: 19px;
  line-height: 39px;
  max-width: 580px;
}
.abtpgsndcontsec {
  padding: 0 0 100px 0;
  width: 100%;
  overflow: hidden;
}
.missionvisiondatasec {
  width: 100%;
  background: #ffffff;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  padding: 30px 30px 60px 30px;
  position: relative;
}

.missionvisiondatasec:before {
  bottom: 31px;
}

.missionvisiondatasec:before {
  content: "";
  position: absolute;
  left: -18px;
  bottom: 50px;
  z-index: 1;
  background: url("https://karishye.co/wp-content/themes/Karishye-child/img/star.png")
    no-repeat left top;
  width: 37px;
  height: 37px;
}
.missionvisiondatasec p {
  font-size: 19px;
  font-weight: 600;
}
.missionvisiondatasec h3 {
  font-weight: 500;
  font-style: normal;
  font-size: 46px;
  line-height: 36px;
  text-transform: capitalize;
  color: #df7900;
  padding: 0 0 25px 0;
  font-size: 36px;
  line-height: 36px;
}

.abtpgthrdcontsec h2 {
  text-align: center;
  font-family: "Fraternite";
  color: #5a1740;
  font-weight: 700;
  font-style: normal;
  font-size: 65px;
  line-height: 65px;
  padding: 0 0 25px 0;
}

.abtpgthrdcontsec p {
  line-height: 32px;
  font-weight: 600;
  font-size: 19px;
}

.aboutUsSection {
  max-width: 1440px;
  margin: auto;
}



@media screen and (max-width: 1440px) {



  .aboutUsSection {
    max-width: 1440px;
    margin: auto;
    padding-right: 7%;
    padding-left: 7%;
  }

}



@media screen and (max-width: 1440px) {
  .abtpgfstcontsec p {
    color: #262525;
    font-weight: 600;
    font-style: normal;
    font-size: 14px;
    line-height: 33px;
    max-width: 580px;
}
.abtpgsndcontsec h2 {
  font-family: "Fraternite";
  color: #5a1740;
  font-weight: 700;
  font-style: normal;
  font-size: 35px;
  line-height: 54px;
  margin-bottom: 2rem;
  margin-top: 0rem;
}
.abtpgsndcontsec h2 {
  font-family: "Fraternite";
  color: #5a1740;
  font-weight: 700;
  font-style: normal;
  font-size: 51px;
  line-height: 61px;
  margin-bottom: 4rem;
  margin-top: 0rem;
} 

.abtpgthrdcontsec h2 {
  text-align: center;
  font-family: "Fraternite";
  color: #5a1740;
  font-weight: 700;
  font-style: normal;
  font-size: 37px;
  line-height: 52px;

}
.abtpgthrdcontsec p {
  line-height: 28px;
  font-weight: 600;
  font-size: 14px;
}
.abtpgfstcontsec h1 {


  font-size: 49px;
  line-height: 63px;
}}