.desktopNav {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 74px;
  z-index: 99;
  background: white;
  border-bottom: 0.5px solid #e5e5e5;
  .innerNav {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1440px;
    margin: auto;
    width: 100%;
  }

  label {
    color: #fff;
    background: #212121;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 155px;
    height: 44px;

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      transition: all 0.5s linear;
      transform: scale(1.05);
    }
  }
}

@media screen and (max-width: 1440px) {
  .desktopNav {
    padding-left: 5%;
    padding-right: 5%;
  }
}
@media screen and (max-width: 900px) {
  .desktopNav {
    padding-left: 5%;
    padding-right: 5%;
    z-index: 999;
    label {
      font-size: 13px;
      width: 114.605px;
      height: 36.154px;
    }
  }
}
