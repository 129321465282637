.landingPage {
  height: 100vh;
  padding-top: 74px;
  .blackint {
    height: calc(50vh + 37px);
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    background: #00000057;
  }
  position: relative;
  .bottomSection {
    display: flex;
    align-items: self-start;
    height: calc(50vh - 63px);
    justify-content: space-between;
    margin-top: 16px;

    .box {
      width: 33.33%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      position: relative;
      cursor: pointer;
      &:hover {
        h2 {
          transform: scale(1.03);
        }
        P {
          transform: scale(1.03);
        }
      }

      h2 {
        color: #fff;
        text-align: center;
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px; /* 133.333% */
      }
      p {
        color: #fff;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 17px;
        margin: auto;
        text-align: center;
        font-family: Montserrat;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px; /* 181.818% */
      }
    }
  }

  .topSecton {
    background: #212121;
    height: calc(50vh - 37px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;

    h1 {
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 45px;
      font-style: normal;
      position: relative;
      z-index: 3;
      font-weight: 700;
      line-height: 40px; /* 88.889% */
    }
    h5 {
      color: #fff;
      text-align: center;
      font-family: Montserrat;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px; /* 136.364% */
      position: relative;
      z-index: 3;
    }
  }
}

@media screen and (max-width: 900px) {
  .landingPage .topSecton {
    height: calc(100vh - 74px);
    padding-left: 5%;
    padding-right: 5%;
  }
  .landingPage .blackint {
    height: 100vh;
  }
  .landingPage .topSecton h1 {
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 55px;
  }

  .landingPage .bottomSection .box {
    width: 100%;

    height: 372px;
  }
  .landingPage .bottomSection {
    display: block;
    align-items: self-start;
    height: auto;
    justify-content: space-between;
  }
  .landingPage .topSecton h5 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    position: absolute;
    bottom: 20px;
  }
}
